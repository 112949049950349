import { useGetUserQuery } from "../api/apiSlice";
import { canAccess, UserPermissions } from "../utils/permissions";
import { UserRole } from "../utils/types";

/**
 * Custom hook to check if the user has a specific permission.
 *
 * @param {UserPermissions} requiredPermission
 * @returns {boolean} - Returns true if the user has the required permission, otherwise false.
 */
export const useHasPermission = (requiredPermission: UserPermissions) => {
  const { data } = useGetUserQuery({});
  const userRoles = data?.roles?.map((role: UserRole) => role.role) || [];

  return canAccess(userRoles, requiredPermission);
};

/**
 * Custom hook to check if the user has an admin role.
 *
 * @returns {Object} - An object containing the hasAdminRole status and loading status.
 * @returns {boolean} hasAdminRole - Returns true if the user has an admin role, otherwise false.
 * @returns {boolean} isLoading - Returns true if the user data is still loading, otherwise false.
 */
export const useHasAdminRole = (): {
  hasAdminRole: boolean;
  isLoading: boolean;
} => {
  const { data, isLoading } = useGetUserQuery({});
  return { hasAdminRole: data?.roles?.some((role: UserRole) => role.role?.toLowerCase() === "admin"), isLoading };
};

/**
 * Hook which returns true if user is either Admin or Approver
 * @returns {boolean} - Returns true if the user is either an Admin or Approver, otherwise false.
 */
export const useIsAdminOrApprover = (): boolean => {
  const { data } = useGetUserQuery({});
  const userRoles = data?.roles?.map((role: UserRole) => role.role) || [];

  return userRoles.some((role: string) => role.toLowerCase() === "admin" || role.toLowerCase() === "approver");
};

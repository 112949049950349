import { useState } from "react";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { ConfirmDialog, CustomTabPanel, Table } from "../../components";
import { appendSearchParams } from "../../utils";
import { ILanguage, ModalType, TabKeyEnum } from "../../utils/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteLanguageMutation, useEditLanguageMutation, useGetLanguagesQuery } from "../../api/apiSlice";
import React from "react";
import { useGetLanguagesTableColumns } from "./useGetLanguagesTableColumns";
import toast from "react-hot-toast";
import { Drawer } from "../../components/Drawer/Drawer";
import { ViewLanguage } from "./ViewLanguage";
import { CreateOrEditLanguage } from "./CreateOrEditLanguage";
import { UserPermissions } from "../../utils/permissions";
import { useHasPermission } from "../../hooks";
import { PageTabParams } from "../../utils/constants";

export const Languages = () => {
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [urlParams] = React.useState(new URLSearchParams(location.search));
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [activeDrawer, setActiveDrawer] = useState<ModalType>("Create");
  const [activeLanguage, setActiveLanguage] = useState<ILanguage | null>(null);
  const [isUnpublishDialogOpen, setIsUnpublishDialogOpen] = React.useState(false);
  const [isPublishDialogOpen, setIsPublishDialogOpen] = React.useState(false);
  const [isReviewDialogOpen, setIsReviewDialogOpen] = React.useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filter, setFilter] = useState<string | undefined>(undefined);

  const [editLanguage, editResult] = useEditLanguageMutation();
  const [deleteLanguage, deleteResult] = useDeleteLanguageMutation();

  const PageTabParamsWithoutArchived = PageTabParams.filter(({ value }) => value !== TabKeyEnum.Archived);

  const activeTabParam = (urlParams.get("activeTab") ?? PageTabParams[0].value) as TabKeyEnum;
  const activeTabValue = Math.max(
    0,
    PageTabParams.findIndex(({ value }) => value === activeTabParam),
  );

  const apiSupportedParams = PageTabParams[activeTabValue].params;
  const { data, isLoading, isFetching } = useGetLanguagesQuery({
    ...apiSupportedParams,
    lang: filter || undefined,
    page: pagination.pageIndex + 1,
    per_page: pagination.pageSize,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const activeTab = PageTabParams[newValue].value;
    const newUrlParams = appendSearchParams({ activeTab }, urlParams);
    navigate(`${location.pathname}?${newUrlParams.toString()}`, {
      replace: true,
    });
  };

  const handleEditLanguage = () => {
    setActiveDrawer("Edit");
    setIsDrawerOpen(true);
  };

  const openCreateLanguageDialog = () => {
    setHoveredRow(-1);
    setActiveLanguage(null);
    setActiveDrawer("Create");
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setActiveLanguage(null);
  };

  const handlePreviewLanguage = () => {
    setActiveDrawer("Preview");
    setIsDrawerOpen(true);
  };

  const columns = useGetLanguagesTableColumns({
    activeTab: activeTabParam,
    setHoveredRow,
    hoveredRow,
    handlePreviewLanguage,
    handleEditLanguage,
    handleUnpublishLanguage: () => handleToggleUnpublishConfirmDialog(true),
    handlePublishLanguage: () => handleTogglePublishConfrimDialog(true),
    handleRemoveLanguage: () => handleToggleRemoveConfirmDialog(true),
  });

  const handleToggleUnpublishConfirmDialog = (open: boolean) => {
    setIsUnpublishDialogOpen(open);
  };

  const handleTogglePublishConfrimDialog = (open: boolean) => {
    setIsPublishDialogOpen(open);
  };

  const handleToggleReviewConfrimDialog = (open: boolean) => {
    setIsReviewDialogOpen(open);
  };

  const handleToggleRemoveConfirmDialog = (open: boolean) => {
    setIsRemoveDialogOpen(open);
  };

  const handleRemoveTag = () => {
    if (activeLanguage?.id) {
      deleteLanguage({ id: activeLanguage.id })
        .unwrap()
        .then(() => {
          handleToggleRemoveConfirmDialog(false);
          toast.success("Language removed successfully");
        })
        .catch(error => {
          toast.error(error?.data || "Failed to remove language");
        });
    } else {
      console.error("No language selected");
    }
  };

  const handlePublishChange = (published: TabKeyEnum) => {
    const messages = {
      [TabKeyEnum.Published]: {
        value: 1,
        success: "Language published successfully",
        error: "Failed to publish language",
      },
      [TabKeyEnum.ReadyForReview]: {
        value: -1,
        success: "Language saved successfully",
        error: "Failed to saved language",
      },
      [TabKeyEnum.Unpublished]: {
        value: 0,
        success: "Language unpublish successfully",
        error: "Failed to unpublish language",
      },
      [TabKeyEnum.Archived]: {
        value: 0,
        success: "Language archived successfully",
        error: "Failed to archived language",
      },
    };

    if (activeLanguage) {
      editLanguage({
        id: activeLanguage.id,
        lang: activeLanguage.lang,
        published: messages[published].value,
      })
        .unwrap()
        .then(() => {
          handleToggleUnpublishConfirmDialog(false);
          handleTogglePublishConfrimDialog(false);
          handleToggleReviewConfrimDialog(false);
          handleDrawerClose();
          toast.success(messages[published].success);
        })
        .catch(error => {
          toast.error(error?.data || messages[published].error);
        });
    } else {
      console.error("No language selected");
    }
  };

  const canCreateLanguages = useHasPermission(UserPermissions.CREATE_LANGUAGES);

  return (
    <>
      <Drawer title={`${activeDrawer} Language`} onClose={handleDrawerClose} open={isDrawerOpen} sx={{ width: "500px", height: "100%" }}>
        {isDrawerOpen && (activeDrawer === "Create" || activeDrawer === "Edit") && (
          <CreateOrEditLanguage
            activeTab={activeTabParam}
            handleClose={handleDrawerClose}
            modalType={activeDrawer}
            language={activeLanguage}
          />
        )}
        {isDrawerOpen && activeDrawer === "Preview" && (
          <ViewLanguage
            activeTab={activeTabParam}
            handleClose={handleDrawerClose}
            handleEditLanguage={handleEditLanguage}
            language={activeLanguage}
            handlePublishLanguage={() => handleTogglePublishConfrimDialog(true)}
            handleUnpublishLanguage={() => handleToggleUnpublishConfirmDialog(true)}
            handleReviewLanguage={() => handleToggleReviewConfrimDialog(true)}
          />
        )}
      </Drawer>
      <ConfirmDialog
        open={isUnpublishDialogOpen}
        title={`Unpublish Language: ${activeLanguage?.lang}`}
        body={`Are you sure you want to unpublish this language?`}
        onClose={() => handleToggleUnpublishConfirmDialog(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.Unpublished)}
        loading={editResult.isLoading}
      />
      <ConfirmDialog
        open={isPublishDialogOpen}
        title={`Publish Language: ${activeLanguage?.lang}`}
        body={`Are you sure you want to publish this language?`}
        onClose={() => handleTogglePublishConfrimDialog(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.Published)}
        loading={editResult.isLoading}
      />
      <ConfirmDialog
        open={isReviewDialogOpen}
        title={`Save Language: ${activeLanguage?.lang}`}
        body={`Are you sure you want to save this language?`}
        onClose={() => handleToggleReviewConfrimDialog(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.ReadyForReview)}
        loading={editResult.isLoading}
      />
      <ConfirmDialog
        open={isRemoveDialogOpen}
        title={`Remove Language: ${activeLanguage?.lang}`}
        body={`Are you sure you want to remove this language? This action is permanent and cannot be undone.`}
        onClose={() => handleToggleRemoveConfirmDialog(false)}
        onConfirm={handleRemoveTag}
        loading={deleteResult.isLoading}
      />

      <Box display="flex" justifyContent="space-between">
        <Tabs value={activeTabValue} onChange={handleTabChange}>
          {PageTabParamsWithoutArchived.map(value => {
            return (
              <Tab
                key={value.label}
                label={value.label}
                sx={{
                  p: 2,
                  mr: 2,
                }}
              />
            );
          })}
        </Tabs>
        {canCreateLanguages && (
          <Box>
            <Button onClick={openCreateLanguageDialog} sx={{ borderRadius: 0.5 }} color="primary" variant="outlined">
              CREATE LANGUAGE
            </Button>
          </Box>
        )}
      </Box>

      <Box sx={{ width: "100%" }}>
        <CustomTabPanel value={activeTabValue} index={activeTabValue}>
          <Table
            columns={columns}
            data={data?.data ?? []}
            manualPagination
            onPaginationChange={setPagination}
            manualFiltering
            onGlobalFilterChange={setFilter}
            enableSorting={false}
            state={{ pagination }}
            rowCount={data?.total}
            loading={isLoading || isFetching}
            muiTableBodyRowProps={({ row }: { row: { id: string; index: number; original: ILanguage } }) => ({
              onMouseEnter: () => {
                setHoveredRow(row.index);
                setActiveLanguage(row.original);
              },
              onMouseLeave: () => {
                setHoveredRow(-1);
              },
              onClick: () => {
                handlePreviewLanguage();
              },
            })}
          />
        </CustomTabPanel>
      </Box>
    </>
  );
};
